@import url("https://fonts.googleapis.com/css?family=Red+Hat+Display&display=swap");

body {
  background-color: #16162c !important;
  color: white !important;
}

p {
  text-align: left;
  font-size: 18px;
}

.App {
  /* margin: 0 10em; */
}

.App-header {
  background-color: #1b1464;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1,
h2,
h3 {
  font-family: "Red Hat Display", sans-serif;
  text-align: left;
}

h2 {
  font-size: 2em;
  text-align: left;
  font-weight: bold;
}

h3 {
  font-size: 1.25em !important;
}

.nav-bar {
  color: #ffffff;
  background-color: rgb(158, 182, 184);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 30em !important;
  height: 3em;
}

.nav-link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: 0.5s;
  display: flex !important;
}

.nav-link:hover {
  color: rgba(125, 113, 192, 0.788);
}

.nav-link-text .nav-link-text:hover {
  color: #ffffff;
  text-decoration: none;
}

.portfolio-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.portfolio-header {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: 1em;
  padding: 1em;
  /* width: 20em; */
  /* height: 20em; */
  background-color: #5e5a9d;
}

.portfolio-image {
  display: flex;
  flex: 1;
  margin: 1em 0;
  /* max-height: 20em; */
  max-width: 20em;
  transition: transform 0.2s;
  align-self: center;
}

.portfolio-image:hover {
  transform: scale(1.2);
}

.fb-header {
  max-height: 33% !important;
}

.about-image {
  object-fit: cover;
  object-position: 100% 0;
  min-width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 2em 2em 0;
  flex: 1;
}

.layout {
  margin: 2em 6em;
}

.about-layout {
  display: flex;
  flex-direction: row;
}

.about-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 5;
}

.social-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.text-light {
  color: #c5c4d6;
}

.text-purple,
.card-link {
  color: #16162c;
}

.text-red {
  color: #df4444;
}

.text-teal {
  color: #28656a;
}

.text-yellow {
  color: #eea33c;
}

.border-red {
  border: 1px solid #df4444;
}

.border-teal {
  border: 1px solid #28656a;
}

.border-yellow {
  border: 1px solid #eea33c;
}

.card {
  margin-bottom: 1em;
}

.card-body-empty {
  background-color: #16162c;
  color: #ffffff;
}

.card-header {
  font-size: 2em;
  text-align: left;
  background-color: transparent !important;
}

.card-title,
.card-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.card-red {
  background-color: #df4444 !important;
  color: white;
  border: 2px solid #df4444 !important;
}

.card-teal {
  background-color: #28656a !important;
  color: #ffffff;
  border: 2px solid #28656a !important;
}

.card-yellow {
  background-color: #eea33c !important;
  color: #16162c;
  border: 2px solid #eea33c !important;
}

.card-lt-purple {
  background-color: #5e5a9d !important;
  color: #16162c;
  border: 2px solid #5e5a9d !important;
}
